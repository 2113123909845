.date-color {
  color: #505050;
}
.vertical-timeline-element-icon svg {
  width: 30px;
  height: 30px;
  margin-left: -17px;
  top: 27px;
}
.svg-inline--fa.fa-w-16 {
  width: 2em;
}
